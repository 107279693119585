<template>
  <div>
    <slot name="header"
      :hasUpdate="hasUpdate"
      :hasError="hasError"
      :hasLinksError="hasLinksError"
      :saveFlowBuild="saveFlowBuild" />
    <TabEditor :editorKey="editorKey" :editorMain="editorMain">

      <Tab content="MenuGeneralInformation">
        <template #header>
          <span :class="{'p-error': hasError}">
            <i v-if="hasError" class="mr-1 pi pi-exclamation-triangle"></i> {{ $t('tabs.generalInformation') }}
          </span>
        </template>
      </Tab>
      <Tab content="MenuMediaSections">
        <template #header>
          <span>
            {{$t('tabs.mediaSections')}}
          </span>
        </template>
      </Tab>
      <Tab content="MenuMedias">
        <template #header>
          <span>
            {{$t('tabs.medias')}}
          </span>
        </template>
      </Tab>
      <Tab content="MenuHomeConfig">
        <template #header>
          <span>
            {{$t('tabs.homeConfig')}}
          </span>
        </template>
      </Tab>
      <Tab content="MenuStaticTranslations">
        <template #header>
          <span>
            {{$t('tabs.staticTranslations')}}
          </span>
        </template>
      </Tab>
    </TabEditor>
    <Teleport to=".main-breadcrumb">
      <InlineMessage v-if="isPreviousCycle" severity="error" :closable="false" icon="" class="updateCycle-alert flex">
        <div class="flex align-items-center">
          <span class="underline text-xl ml-4 mr-8">{{( $t('menus.warningEditingPreviousCycle')) }}</span>
          <Button :label="$t('menus.backCurrentCycle')" @click="$router.push({name: 'PortalManagerMenusEditing'})" />
        </div>        
      </InlineMessage>
    </Teleport>
  </div>
</template>

<script>
import { provide, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useFlowBuilder } from "@/compositions"
import TabEditor from '@/components/base/TabEditor.vue'
import InlineMessage from 'primevue/inlinemessage'

function onCreate (doc) {
  return {
    name: doc.name
  }
}

export default {
  name: 'MenuEditingCore',
  props: {
    doc: Object,
    flow: String,
    editorMain: String,
  },
  emits: ['has-update', 'field-change'],
  components: {
    TabEditor,
    Tab: TabEditor.Tab,
    InlineMessage
  },
  setup (props, { emit }) {
    const route = useRoute()
    
    const isPreviousCycle = computed(() => {
      return route.name === 'PortalManagerMenusEditingPrevious'
    })

    provide('isPreviousCycle', isPreviousCycle)
    return {
      isPreviousCycle,
      ...useFlowBuilder(props.flow, props.doc, {
        editorMain: props.editorMain,
        onCreate,
        onFieldChange: (field, value) => {
          emit('field-change', { field, value })
        },
        onUpdateChange: ({hasUpdate, at}) => {
          emit('has-update', { hasUpdate, at})
        },
        actionFields: [
          { field: 'cycle.sectionsTree', action: 'to-raw' },
          { field: 'cycle.sections', action: 'links-to-ids' }
        ]
      })
    }
  }
}
</script>
