<template>
<div>
    <DataTable
      v-model:filters="lazyParams.filters"
      :totalRecords="totalRecords"
      @row-click="edit($event.data)"
      @page="onLazyParams($event)"
      @sort="onLazyParams($event)"
      @filter="onLazyParams($event)"
      :loading="loading"
      :value="list"
      v-bind="defaultTableProperties"
      >
      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="lazyParams.filters['search'].value" :placeholder="$t('buttons.search')" @keydown.enter="getList()" />
          </span>
          <Button type="button" icon="pi pi-plus" :label="$t('buttons.newMenu')" class="p-button-outlined" @click="create()"/>
        </div>
      </template>

      <template #empty>
        {{$t('menus.list.empty')}}
      </template>

      <template #loading>
        {{ $t('menus.list.loading') }}
      </template>

      <Column field="name" :header="$t('common.name')" sortable />

      <Column field="currentCycle" :header="$t('menus.field.currentCycle')" />

      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('common.actions') }}</div>
          </div>
        </template>

        <template #body="slotProps">
          <div class="flex justify-content-end w-full">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="edit(slotProps.data)" />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
              :loading="slotProps.data.__isRemoving"
              @click="confirmRemove(slotProps.data)"
            />
          </div>
        </template>
      </Column>

    </DataTable>

    <MenuNewDialog v-model="ui.showCreateMenu" />

  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import MenuNewDialog from './components/MenuNew.vue'
import Log from '@/services/logger'

import { createFlowMixin } from '@/mixins'

const KFlow = "portalmanager:menu"
const menuMixin = createFlowMixin({
  KFlow,
  dataName: 'menu',
  showCreate: 'showCreateMenu',
  getName: (e) => (e.name),
  editUrl: (e) => (`/portal-manager/menus/${e.id}`)
})

export default {
  name: 'MenuListing',
  components: {
    MenuNewDialog,
    DataTable,
    Column,
    InputText
  },
  data: () => ({
    loading: false,
    totalRecords: 0,
    list: null,
    lazyParams: {
      sortField: 'name',
      sortOrder: 1,
      filters: {
        'search': {value: '', matchMode: 'contains'},
      },
    },
    ui: {
      showCreateMenu: false
    }
  }),
  mixins: [menuMixin],
  mounted () {
    this.$breadcrumb.setCurrent('Menus', [
      {label: 'Portal Manager', disabled: true},
      {label: 'Menus', disabled: true}
    ])
    this.getList()
  },
  methods: {
    create () {
      this.ui.showCreateMenu = true
    },
    resetFilters () {
      // TODO:
    },
    async getList () {
      const params = JSON.parse(JSON.stringify(this.lazyParams))
      this.loading = true
      try {
        const { data: list, total } = await this.$pxstream.portalmanager.getMenuList(params)
        this.totalRecords = total
        this.list = list
      } catch (e) {
        Log.Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
