import { useStorage } from '@vueuse/core'

export const defaultTableProperties = {
  stripedRows: true,
  showGridlines: true,
  filterDisplay: "menu",
  class: "p-datatable-sm",
  responsiveLayout: "scroll",
  rowHover: true,
  lazy: true,
  paginator: true,
  sortField: "name",
  sortOrder: 1,
  rows: 20,
  rowsPerPageOptions: [10, 20, 50],
}

const createMixin = (opts) => {

  const { KFlow, dataName, getName, showCreate, editUrl, defaultSort } = opts

  let sort = {}

  if (defaultSort && defaultSort.sortField && defaultSort.sortOrder) {
    sort = {
      sortField: defaultSort.sortField,
      sortOrder: defaultSort.sortOrder,
    }
  }

  return {
    data: () => ({
      defaultTableProperties,
      loading: false,
      totalRecords: 0,
      list: null,
      lazyParams: {
        ...sort,
        filters: {
          'search': { value: '', matchMode: 'contains' }
        }
      },
    }),
    methods: {
      getLazyParams () {
        return JSON.parse(JSON.stringify(this.lazyParams))
      },
      onLazyParams (e) {
        if (e) {
          this.lazyParams = e
        }
        this.getList()
      },
      edit (e) {
        // TODO: rewrite this into setup composition --> remove mixin
        const tabState = useStorage('tab-editor-memory', {})
        tabState.value.memory = {}
        this.$router.push(editUrl(e))
      },
      create () {
        this.ui[showCreate] = true
      },
      async confirmRemove (data) {
        if (dataName === 'genre') {
          const params = {
            perPage: 0,
            filters: {
              'media.genres.id': {value: [data.id], matchMode: 'contains'},
            }
          }
          try {
            const {total} = await this.$pxstream.portalmanager.getMediaList(params)
            this.displayRemoveMessage(data, dataName, total)
          } catch (err) {
            this.$toast.add({severity: 'error', summary: this.$t('medias.get.error'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
          }
        } else {
          this.displayRemoveMessage(data, dataName)
        }
      },
      displayRemoveMessage (data, dataName, total) {
        this.$confirm.require({
          message: this.$t('medias.remove.message') + (total ? ' ' + this.$t('medias.remove.impactInfo', {total}) : ''),
          header: this.$t('medias.remove.header', {name: getName(data)}),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.remove(data)
          },
          reject: () => {}
        })
      },
      async remove (data) {
        data.__isRemoving = true
        this.$pxstream.deleteOne(KFlow, data.id)
          .then(() => {
            this.$toast.add({ severity: 'success', detail: this.$t('medias.remove.success'), summary: getName(data), file: 4000 })
            data.__isRemoving = false
            this.getList()
          })
          .catch((err) => {
            data.__isRemoving = false
            this.$toast.add({severity: 'error', summary: this.$t('medias.remove.error', { name: getName(data) }), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
          })
      },
      getList () {
        console.warn('Warning! getList not implemented...')
      }
    }
  }
}

export default createMixin