<template>
  <div>
    <DataTable
      v-model:filters="lazyParams.filters"
      ref="dt"
      class="p-datatable-sm"
      stripedRows
      showGridlines
      filterDisplay="menu"
      responsiveLayout="scroll"
      :rowHover="true"
      :value="list"
      :lazy="true"
      :paginator="true"
      :rows="20"
      :totalRecords="totalRecords"
      :loading="loading"
      :sortField="lazyParams.sortField"
      :sortOrder="lazyParams.sortOrder"
      :rowsPerPageOptions="[10,20,50]"
      @row-click="edit($event.data)"
      @page="onLazyParams($event)"
      @sort="onLazyParams($event)"
      @filter="onLazyParams($event)">
      <template #header>
        <div class="flex justify-content-between">
          <div class="flex">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="lazyParams.filters['search'].value" :placeholder="$t('buttons.search')" @keydown.enter="getList()" />
            </span>
            <span class="input-label p-2">{{$t('medias.licensor')}} : </span>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <AutoCompleteSearch
                id="licensor"
                v-model="lazyParams.filters['licensor.name'].value"
                itemValue="id"
                field="name"
                dropdown
                dropdownIcon="standard"
                :suggestions="listingLicensors"
                :suggestionsTotal="listingLicensorsTotal"
                @complete="searchLicensors($event)"
                @keydown.enter="getList()"
              />
            </span>
          </div>
          <Button type="button" icon="pi pi-plus" :label="$t('buttons.newMedia')" class="p-button-outlined" @click="create()"/>
        </div>
      </template>

        <template #empty>
            {{$t('medias.noMediaFound')}}.
        </template>

        <template #loading>
            {{ $t('medias.loadingMedia') }}
        </template>

        <Column :style="{width: '36px'}">
          <template #body="slotProps">
            <div>
              <Icon :icon="typeIcon(slotProps.data)" />
            </div>
          </template>
        </Column>

        <Column field="name" :header="$t('common.title')" :sortable="true" @click="edit(slotProps.data)" style="cursor:pointer"></Column>

        <Column field="contentType.name" header="Type"
          :showFilterOperator="false"
          :showFilterMatchModes="false"
          :sortable="true"
        >
          <template #filter="{filterModel}">
            <div v-for="one in contentTypes" :key="one.id" class="field-checkbox">
              <Checkbox :id="one.id" name="contentTypes" :value="one.id" v-model="filterModel.value" />
              <label :for="one.id">{{one.name}}</label>
            </div>
          </template>
        </Column>

        <Column field="licensor.name" :header="$t('medias.licensor')"
          :showFilterOperator="false"
          :showFilterMatchModes="false"
          :sortable="true"
        >
        </Column>

        <Column field="runtime" :header="$t('medias.runtime')"></Column>
        <Column field="files" :header="$t('medias.files')"></Column>

        <Column>
          <template #header>
            <div class="flex justify-content-end w-full">
              <div>{{ $t('common.actions') }}</div>
            </div>
          </template>

          <template #body="slotProps">
            <div class="flex justify-content-end w-full">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="edit(slotProps.data)" />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-outlined"
                :loading="slotProps.data.__isRemoving"
                @click="confirmRemove(slotProps.data)"
              />
            </div>
          </template>
        </Column>

    </DataTable>

    <MediaNew v-model="showCreateMedia"/>

    <Dialog :header="`${$t('actions.remove')} «${selectedMedia.name}»`" v-model:visible="showRemove" :style="{width: '350px'}" modal>
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>{{$t('medias.remove.message', { field: this.$t('common.media') })}}</span>

        <div v-if="selectedMedia && selectedMedia.menus">
          <p class="mt-4 mb-1">{{$t('medias.mediaPresentInMenu')}}:</p>

          <ul class="m-0">
            <li v-for="(menu, index) in selectedMedia.menus.list" :key="`selectedMediaMenu-${index}`">{{ menu.name }}</li>
            <li v-if="selectedMedia.menus.total > menuLimit">{{$t('common.and')}} {{ selectedMedia.menus.total - menuLimit }} {{$t('common.othersMenus')}}</li>
          </ul>
        </div>

      </div>
      <template #footer>
        <Button @click="closeRemove()" :label="$t('actions.no')" icon="pi pi-times"  class="p-button-text"/>
        <Button @click="remove(selectedMedia)" :label="$t('actions.yes')" icon="pi pi-check" class="p-button-text" autofocus />
      </template>
    </Dialog>

  </div>
</template>

<script>
import Log from '@/services/logger'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
// import MultiSelect from 'primevue/multiselect'
import Checkbox from 'primevue/checkbox'

import MediaNew from './components/MediaNew.vue'
import InputText from 'primevue/inputtext'
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import Dialog from 'primevue/dialog'

import { mapGetters } from 'vuex'

import { ref, inject } from 'vue'

import { useToast } from "primevue/usetoast"
import { useRouter } from "vue-router"

import { Icon, addIcon } from '@iconify/vue/dist/offline'

import iconHelp from '@iconify-icons/mdi/help-rhombus-outline'
import iconMusic from '@iconify-icons/mdi/music-box-outline'
import iconMovie from '@iconify-icons/mdi/movie'
import iconPodcast from '@iconify-icons/mdi/podcast'
import iconAlbum from '@iconify-icons/mdi/album'
import iconTv from '@iconify-icons/mdi/television'
import iconRadio from '@iconify-icons/mdi/radio'
import { useTabMemory } from '@/components/base/TabEditor.vue'

addIcon('help', iconHelp)
addIcon('music', iconMusic)
addIcon('movie', iconMovie)
addIcon('podcast', iconPodcast)
addIcon('album', iconAlbum)
addIcon('radio', iconRadio)
addIcon('tv', iconTv)

const KFlow = "portalmanager:media"

export default {
  name: 'MediaListing',
  components: {
    Icon,
    DataTable,
    Column,
  //  MultiSelect,Title
    Checkbox,
    MediaNew,
    InputText,
    Dialog,
    AutoCompleteSearch
  },
  setup: () => {
    const { reset: resetTabMemory } = useTabMemory()
    const pxstream = inject('pxstream')
    const toast = useToast()
    const router = useRouter()

    const menuLimit = 20
    const showRemove = ref(false)
    const selectedMedia = ref({})
    const loading = ref(false)
    const totalRecords = ref(0)
    const list = ref(null)
    const showCreateMedia = ref(false)
    const licensor = ref(null)
    const listingLicensors = ref(null)
    const listingLicensorsTotal = ref(0)
    const lazyParams = ref({
      sortField: 'name',
      sortOrder: 1,
      filters: {
        'search': {value: '', matchMode: 'contains'},
        'licensor.name': {value: '', matchMode: 'contains'},
        'contentType.name': {value: '', matchMode: 'contains'},
      },
    })

    const searchLicensors = async ({query}) => {
      try {
        const qryBuilder = pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await pxstream.resource.getLicensors(qryBuilder.build())
        listingLicensors.value = data
        listingLicensorsTotal.value = total
      } catch (err) {
        toast.add({severity: 'error', summary: 'Failed to get licensors', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    }

    const getList = async () => {
      const params = JSON.parse(JSON.stringify(lazyParams.value))

      if (params.filters['contentType.name']) {
        if (params.filters['contentType.name'].value.length > 0) {
          params.filters['contentType.id'] = params.filters['contentType.name']
        }
        delete params.filters['contentType.name']
      }

      if (params.filters['licensor.name'].value) {
        params.filters['licensor.name'].value = params.filters['licensor.name'].value.name ? [params.filters['licensor.name'].value.name] : [params.filters['licensor.name'].value]
      }

      loading.value = true
      try {
        const {data, total} = await pxstream.portalmanager.getMediaList(params)
        totalRecords.value = total
        list.value = (data || []).map(elem => {
          if (elem.runtime === 0) {
            elem.runtime = ''
          } else {
            const h = Math.floor(elem.runtime / 3600)
            const m = Math.floor(Math.floor(elem.runtime % 3600) / 60)
            const s = Math.floor(elem.runtime % 3600) % 60
            elem.runtime = `${('0'+h).slice(-2)}:${('0'+m).slice(-2)}:${('0'+s).slice(-2)}`
          }
          if (elem.files === -1) {
            elem.files = ''
          }
          return elem
        })
      } catch (err) {
        toast.add({severity: 'error', summary: 'Failed to get media', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
      loading.value = false
    }

    const remove = async (data) => {
      data.__isRemoving = true
      pxstream.deleteOne(KFlow, data.id)
      .then(() => {
        toast.add({severity: 'success', detail: 'Removed with success', summary: data.name, life: 4000})
        data.__isRemoving = false
        getList()
      })
      .catch((err) => {
        data.__isRemoving = false
        toast.add({severity: 'error', summary: 'Failed to remove media '+data.name, detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      })
      closeRemove()
    }

    const confirmRemove = async (data) => {
      showRemove.value = true
      const qryBuilder = pxstream.tools.createQueryBuilder()
      qryBuilder.addFilter('cycle.sections.data.medias', 'contains', [data.id])
      const {data: menus, total} = await pxstream.portalmanager.getMenuList(qryBuilder.build())
      selectedMedia.value = {...data}
      selectedMedia.value.menus = null
      if (menus) {
        menus.sort((a, b) => a.name > b.name)
        selectedMedia.value.menus = {}
        selectedMedia.value.menus.list = menus.splice(0, menuLimit)
        selectedMedia.value.menus.total = total
      }
    }

    const closeRemove = () => {
      showRemove.value = false
    }

    const onLazyParams = (e) => {
      if (e) {
        lazyParams.value = e
      }
      getList()
    }

    const edit = (e) => {
      resetTabMemory()
      router.push(`/portal-manager/media/${e.id}`)
    }

    const create = () => {
      showCreateMedia.value = true
    }

    const typeIcon = (media) => {
      switch (media.mediaType) {
        case 'audio-simple': return 'music';
        case 'audio-album': return 'album';
        case 'audio-podcast': return 'podcast';
        case 'audio-radio': return 'radio';
        case 'short-movie':
        case 'trailer':
        case 'movie': return 'movie';
        case 'tv-series':
        case 'tv-simple': return 'tv';
        default: if (media.mediaType) Log.Debug(`Unknown media : ${media.mediaType}`);
      }

      return 'help'
    }

    return {
      menuLimit,
      loading,
      totalRecords,
      list,
      lazyParams,
      showCreateMedia,
      getList,
      edit,
      create,
      remove,
      typeIcon,
      confirmRemove,
      closeRemove,
      onLazyParams,
      showRemove,
      selectedMedia,
      listingLicensors,
      listingLicensorsTotal,
      searchLicensors,
      licensor
    }
  },
  async mounted () {
    this.$breadcrumb.setCurrent('Media Library', [
      {label: 'Portal Manager', disabled: true},
      {label: 'Media Library', disabled: true}
    ])

    await this.getList()
  },
  computed: {
    ...mapGetters('resource', ['contentTypes', 'licensors'])
  }
}
</script>
