<template>
  <div>
    <div v-if="doc">
      <EditingCore :doc="doc" :flow="flow" @has-update="onUpdateChanged">
        <template #header="{ hasUpdate, hasError, hasLinksError, saveFlowBuild }">

          <div class="flex justify-content-end mb-2">
            <div>
              <Button
              :label="$t('actions.save')"
              icon="pi pi-check"
              iconPos="right"
              :disabled="!hasUpdate || isSaving || hasLinksError('*') || hasError"
              :loading="isSaving"
              @click="save(saveFlowBuild('*'))"
              />
            </div>
          </div>

          <!-- <div v-if="hasError || hasLinksError('*')">
            <Message severity="warn">Media has errors.</Message>
          </div> -->

        </template>
      </EditingCore>
    </div>
    <div v-else>
      <div class="flex justify-content-center">
        <p class="text-4xl font-light text-center">
          {{ isReloading ? $t('common.reloading') : $t('medias.loadingMedia') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import Message from 'primevue/message'

import { useFlowCruder } from '@/compositions/useFlowCruder'
import { useFlowReset } from '@/compositions/useFlowEditor'
import Log from '@/services/logger'

import { ref } from 'vue'
import { LOGIN_PAGE } from '@/router'

const KFlow = "portalmanager:media"

export default {
  name: 'MediaEditing',
  setup () {
    const isReloading = ref(false)
    const doc = ref(null)
    const hasUpdate = ref(false)
    const lastUpdate = ref(new Date(0))

    return {
      isReloading,
      hasUpdate,
      lastUpdate,
      flow: KFlow,
      doc,
      ...useFlowCruder()
    }
  },
  components: {
    // Message
  },
  methods: {
    async save (req) {
      Log('@@@ SAVE @@@')
      Log('Req: ', JSON.parse(JSON.stringify(req)))
      try {
        const { redirect } = await this.flowSave(req)
        this.$toast.add({severity: 'success', detail: 'Saved with success', summary: req.doc.name, life: 4000})
        if (redirect) {
          this.$router.push(`/portal-manager/media/${redirect}`)
          return
        }

        this.isReloading = true
        useFlowReset()
        // Force EditingCore setup by removing dom
        this.doc = null
        const { data } = await this.flowGet(KFlow, this.$route.params.id)
        this.doc = data
        this.isReloading = false
        // Reset hasUpdate flag
        this.onUpdateChanged({
          hasUpdate: false,
          at: new Date(),
        })
      } catch (e) {
        Log.Error(e)
        this.$toast.add({severity: 'error', summary: 'Failed to save media', detail: e.error || e.message, life: 4000})
      }
    },
    onUpdateChanged ({hasUpdate, at}) {
      this.hasUpdate = hasUpdate
      this.lastUpdate = at
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasUpdate) {
      return next()
    } else if (to.name === LOGIN_PAGE && to.query.redirect) {
      return next()
    }
    this.$confirm.require({
      header: this.$t('guards.leaveEditor.header'),
      message: this.$t('guards.leaveEditor.message'),
      acceptLabel: this.$t('actions.yes'),
      rejectLabel: this.$t('actions.no'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => { next() },
      reject: () => { next(false) }
    })
  },
  async mounted () {
    useFlowReset()

    const docId = this.$route.params.id
    let title = `Media ${docId}`
    let owner = '-- No Owner--'

    try {
      const {data} = await this.flowGet(KFlow, docId)
      this.doc = data
      title = this.doc.name
      owner = this.doc.owner?.username || owner
      if (owner) owner = `By ${owner}`
    } catch (err) {
      this.$toast.add({severity: 'error', summary: 'Failed to get media', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      setTimeout(() => this.$router.push('/portal-manager/media-library', 4000))
    }

    this.$breadcrumb.setCurrent(title, [
      {label: 'Portal Manager', disabled: true},
      {label: 'Media Library', disabled: false, to: '/portal-manager/media-library'},
      {label: title, disabled: true}
    ], {caption: owner, badge: docId === 'new' ? 'Draft' : ''})
  }
}
</script>
